import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentInstrumento = () => {
    const data = useStaticQuery(graphql`
    {
      nodeContenidoEdutoolkit(id: {eq: "ea5043a4-5ea6-5870-8fe1-b61d953d2a50"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodeContenidoEdutoolkit.body.value }}></div>
        </div>
    )
}

export default ComponentInstrumento

