import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentDiseno = () => {
    const data = useStaticQuery(graphql`
    {
      nodeContenidoEdutoolkit(id: {eq: "b534ce60-e134-5c26-9569-4abfa739055a"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodeContenidoEdutoolkit.body.value }}></div>
        </div>
    )
}

export default ComponentDiseno

