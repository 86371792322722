import * as React from "react"
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComponentInstrumento from "../hooks/instrumento";
import ComponentMetodo from "../hooks/metodo";
import ComponentPublicaciones from "../hooks/publicaciones";

export default function EduToolKit () {
  return (
    <Layout pageInfo={{ pageName: "OpenResearchTec" }}>
    <SEO title="OpenResearchTec" />
    <br />
    <br />
    <br></br>
    <Tabs defaultActiveKey="instrumento" id="uncontrolled-tab-example" className="mb-3 nav-fill integrantes-tab">
      <Tab eventKey="instrumento" style={{textAlign: `justify`}} className="pt-3" title="Instrumento de evaluación">
    
      <ComponentInstrumento></ComponentInstrumento>
      </Tab>
      <Tab eventKey="metodo" style={{textAlign: `justify`}}  className="pt-3" title="Método de investigación">
      <ComponentMetodo></ComponentMetodo>
      </Tab>
      <Tab eventKey="publicaciones" style={{textAlign: `justify`}}  className="pt-3" title="Publicaciones">
        <ComponentPublicaciones></ComponentPublicaciones>
      </Tab>
    </Tabs>
  </Layout>
  )
}